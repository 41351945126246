import React from "react";
import { Helmet } from "react-helmet";
import favicon from '~image/favicon.png';
import Header from "../Header";
export default function Layout({
  children,
}) {
  return (
    <>
      <Helmet htmlAttributes={{
        lang: 'en-lk',
      }}>
        <title>Stainless Steel Hotel Kitchen Equipment Sri Lanka | Sunru </title>
        <link rel="icon" type="image/png" href={favicon} />
        <meta name="description" content="20+ Years of Industry experience. Flexible Designs for Best Stainless Steel Hotel Kitchen Equipment Sri Lanka. Sunru Fabrications Is a leading stainless steel fabricator in Sri Lanka" />
        <link rel="canonical" href="https://sunrukitchen.com" />
      </Helmet>
      <Header />
      {children}
    </>
  )
}

// import menuImage from '../assets/image/menu-image.webp'

export const menuItems = [
        //  {
        //    name: "services",
        //    label: "Services",
        //    items: [
        //      { name: "receiving-service", label: "Receiving" },
        //      { name: "storage-service", label: "Storage" },
        //      { name: "pick-pack-service", label: "Pick & Pack" },
        //      { name: "standard-packing-service", label: "Standard Packing" },
        //      { name: "shipping-service", label: "Shipping" },
        //    ],
        //  },
        //  {
        //    name: "about-us",
        //    label: "About Us",
        //  },
       ];
export default menuItems;

import React from "react"
import { Navbar } from "react-bootstrap"
import siteBrandDark from "~image/logo/logo-black.jpg"
import siteBrandLight from "~image/logo/logo-white.jpg"
import Menu from "./Menu"
const SiteNavbar = ({ buttonBlock, darkLogo, customLogo, defaultLogo }) => {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
        className="site-navbar"
      >
        <Navbar.Brand href="/">

          {defaultLogo ? (<img src={defaultLogo} alt="site-brand" />) : customLogo ? (
            <img src={customLogo} alt="site-brand" />
          ) : (
            <img style={{ outlineStyle: 'solid', outlineColor: '#ed6e07', borderRadius: "4px" }} src={darkLogo ? siteBrandDark : siteBrandLight} alt="sunru-fabrications-logo" />
          )}
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="hamburgur"
        />
        <Menu />
        {buttonBlock}
      </Navbar>
    </>
  )
}

export default SiteNavbar
